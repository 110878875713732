/**
 * Add a listener to the global object to catch the unhandledrejection event,
 * which is rethrown in the global scope of the script it is thrown in.
 * The event listener is attached to globalThis so this can work in window and Workers.
 *
 * This is loaded as quickly as possible using a script tage in headHeader.jsp.
 *
 * This way we can detect unexpected errors thrown from promise callbacks
 * without having to add .catch(Bugsnag.notify) everywhere.
 */
globalThis.addEventListener("unhandledrejection", (event) => {
    // An interface isn't used here b/c that introduces a race condition between the script tag
    // that runs this code and the script tag that runs where we define the interface (ex. Rest.ts).
    // It is theoretically possible for this event handler to be triggered before that interface
    // object is in the global scope, causing an additional error.
    if (typeof event.reason?.show === "function") {
        event.reason.show();
    } else if (event.reason instanceof Error) {
        // Forward to the global error handler. Only actual JS errors, not other rejection values
        // which indicate normal occurrences such as failed rest requests rather than actual bugs.
        throw event.reason;
    }
});
